import { useContext, useState, useMemo } from 'react';
import { createContext } from 'react';


const UserContext = createContext();

export const useUserProvider = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [fn, setFn] = useState({});

  const providerValue = useMemo(() => ({ fn, setFn }), [fn, setFn]);
  return (
    <UserContext.Provider
      value={{
        fn,
        setFn,
        providerValue,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
