import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// import { connect } from "./redux/blockchain/blockchainActions";
//import { fetchData } from './redux/data/dataActions';
import * as s from './styles/globalStyles.js';
import styled from 'styled-components';
import logo1 from './config/images/logo.png';
import cloud1Flip from './config/images/cloud2.svg';
import cloud2 from './config/images/cloud3.svg';
import cloud3 from './config/images/cloud4.svg';
import cloud4 from './config/images/cloud5.svg';
import gsap from 'gsap';
import details from './config.json'
//import P3Abi from './components/P3_ABI.json';
import '@rainbow-me/rainbowkit/styles.css';
import {
  getDefaultWallets,
  RainbowKitProvider,
  darkTheme,
} from '@rainbow-me/rainbowkit';
import {
  configureChains,
  createClient,
  useAccount,
  WagmiConfig,
} from 'wagmi';
import { fantom, mainnet, polygon, goerli } from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';
import ConnectButtonComponent from './components/ConnectButtonComponent';

import Nfts from './components/Nfts';
import abi from './contract-abi.json';
import { useUserProvider } from './context/UserContext';

//const {feedback} = require('./components/ConnectButtonComponent')
const { chains, provider } = configureChains(
  [mainnet, fantom, polygon, goerli],
  [publicProvider()]
);

const { connectors } = getDefaultWallets({
  appName: 'MintDApp',
  chains,
});

const wagmiClient = createClient({
  connectors,
  provider,
});

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 20px;
  border: none;
  margin-left: 5px;
  margin-right: 5px;
  background-color: var(--secondary);
  font-weight: bold;
  color: var(--accent-text);
  width: 234px;
  height: 50px;
  cursor: pointer;
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 20px;
  border: none;
  background-color: var(--secondary);
  font-weight: bold;
  color: var(--accent-text);
  width: 56px;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledSelectButton = styled.button`
  padding: 5px;
  border-radius: 20px;
  border: none;
  margin-left: 5px;
  margin-right: 5px;
  background-color: var(--secondary);
  font-weight: bold;
  color: var(--accent-text);
  width: 170px;
  height: 50px;
  cursor: pointer;
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const ResponsiveWrapperHeader = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 20px;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 116px;
  @media (min-width: 767px) {
    width: 116px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const HalfStyle = styled.img`
  transition: width 0.5s;
  transition: height 0.5s;
  max-width: 4%;
  margin: 0;
  position: absolute;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  @media (max-width: 767px) {
    max-width: 10%;

    top: 17%;
  }
`;

export const HeadingMain = styled.h1`
  font-size: 170px;
  font-weight: 400;
  line-height: 223.53px;
  color: #fff;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
  @media (max-width: 767px) {
    font-size: 100px;
    line-height: 111px;
  }
`;
export const FooterImg = styled.img`
  width: 70%;
  height: auto;
  @media (max-width: 767px) {
    width: 100%;
    height: auto;
  }
  @supports (-webkit-touch-callout: none) {
    width: 100%;
    height: auto;
  }
`;
export const FooterDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Styledsocial = styled.img`
  width: 36px;
  height: 29px;
  margin-left: 30px;
  @media (min-width: 767px) {
    width: 36px;
    height: 29px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;
export const Styledsocialleft = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;
export const StyledsocialRight = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const StyledImg = styled.img`
  max-width: 70%;
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledIcon = styled.ul`
  display: flex;
`;

export const IconItem = styled.li`
  flex-direction: row;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

export const StyledSun = styled.img`
  width: 300px;
  position: absolute;
  top: 100px;
  right: 90px;

  @media (max-width: 767px) {
    width: 150px;
    right: 40px;
  }
`;

export const StyledCloud = styled.img`
  width: 200px;
  position: absolute;
  top: 150px;

  @media (max-width: 767px) {
    transform: scale(0.5);
  }
`;

export const StyledCloud1 = styled(StyledCloud)`
  left: 1300px;
  width: 150px;

  @media (max-width: 767px) {
    left: 20px;
  }
`;

export const StyledCloud2 = styled(StyledCloud)`
  left: 700px;
  top: 200px;

  @media (max-width: 767px) {
    left: 40px;
  }
`;

export const StyledCloud3 = styled(StyledCloud)`
  left: 300px;
  top: 250px;
  width: 250px;

  @media (max-width: 767px) {
    left: 90px;
  }
`;

export const StyledCloud4 = styled(StyledCloud)`
  left: 600px;
  @media (max-width: 767px) {
    left: 150px;
  }
`;

function App() {
  const data = useSelector((state) => state.data);
  //const [feedback, setFeedback] = useState('');
  const [selected, setSelected] = useState({
    value: false,
  });
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: '',
    SCAN_LINK: '',
    NETWORK: {
      NAME: '',
      SYMBOL: '',
      ID: 0,
    },
    NFT_NAME: '',
    SYMBOL: '',
    MAX_SUPPLY: 1,
    WEI_COST: 1,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: '',
    MARKETPLACE_LINK: '',
    SHOW_BACKGROUND: true,
  });
  
  const { fn, setFn } = useUserProvider();
  const { isConnected } = useAccount();

  const getConfig = async () => {

    SET_CONFIG(details);
  };

  // animations effect
  useEffect(() => {
    let x = 0;
    if (window.innerWidth < 700) {
      x = 200;
    } else {
      x = 1000;
    }

    gsap.to('body', {
      duration: 0,
      css: { visibility: 'visible', overflow: 'hidden' },
    });

    const tl = gsap.timeline({ repeat: -1 });

    tl.to('.spin1', { rotation: 360, delay: 3 });

    tl.to('.spin2', { rotation: 360, delay: 3 }, '<');

    gsap.to('.sun', {
      rotation: 360,
      duration: 25,
      repeat: -1,
      ease: 'none',
    });

    gsap.fromTo(
      '.cloud1',
      { x: -300 },
      {
        x: x,
        duration: 20,
        yoyo: true,
        repeat: -1,
        ease: 'back.inOut(2)',
      }
    );

    gsap.to('.cloud2', {
      x: window.innerWidth / 2,
      duration: 20,
      yoyo: true,
      repeat: -1,
      ease: 'sine.inOut',
    });

    gsap.to('.cloud3', {
      x: -window.innerWidth / 2,
      duration: 20,
      yoyo: true,
      repeat: -1,
      ease: 'sine.inOut',
    });

    gsap.to('.cloud4', {
      x: window.innerWidth / 2,
      duration: 20,
      yoyo: true,
      repeat: -1,

      ease: 'none',
    });
  }, []);
//console.log(ConnectButtonComponent.feedback);

  useEffect(() => {
    getConfig();
    // eslint-disable-next-line
  }, []);
  //console.log(process.env.REACT_APP_APIKEY);
  return (
    <s.Screen>
      <s.Container
        flex={1}
        image={CONFIG.SHOW_BACKGROUND ? '/config/images/bgRed.png' : null}
      >
        <s.SpacerMedium />
        <s.SpacerMedium />
        <ResponsiveWrapperHeader>
          <div>
            <HalfStyle
              className='bounce'
              alt={'/config/images/logo.png'}
              src={logo1}
              //height='184px'
              //width='95px'
            />
          </div>
        </ResponsiveWrapperHeader>

        <StyledSun className='sun' src='/config/images/sun2.svg' />

        <div className='clouds'>
          <StyledCloud1 className='cloud1' src={cloud1Flip} />
          <StyledCloud2 className='cloud2' src={cloud2} />
          <StyledCloud3 className='cloud3' src={cloud3} />
          <StyledCloud4 className='cloud4' src={cloud4} />
        </div>
        <s.SpacerMedium />

        <ResponsiveWrapper
          flex={1}
          style={{ padding: 5 }}
          position={'relative'}
        >
          <s.Container
            flex={38}
            jc={'center'}
            ai={'center'}
            style={{ paddingTop: '50px' }}
          >
            <s.TextTitle
              style={{
                textAlign: 'center',
                fontSize: '40px',
                fontWeight: '400',
                color: 'var(--accent-text)',
                zIndex: '1',
              }}
            >
              Yurei 幽霊
            </s.TextTitle>
            {isConnected && fn.selectedNetwork === 'PRIVATE' ? (
              <Nfts props={selected.value} />
            ) : (
              <HeadingMain>
                {fn.supply}/{CONFIG.MAX_SUPPLY}
              </HeadingMain>
            )}

            {fn.supply >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ textAlign: 'center', color: 'var(--accent-text)' }}
                >
                  The sale has ended.
                </s.TextTitle>
                <s.TextDescription
                  style={{ textAlign: 'center', color: 'var(--accent-text)' }}
                >
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>

                <StyledLink target={'_blank'} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                <s.TextTitle
                  style={{ textAlign: 'center', color: 'var(--accent-text)' }}
                >
                  1 Joumejin costs 0.005 for holders and 0.01 for Phurba only holders.
                  0.05 for public
                </s.TextTitle>

                <s.SpacerSmall />
                <s.Container ai={'center'} jc={'center'}>
                  <s.SpacerSmall />
                  <div>
                    <WagmiConfig client={wagmiClient}>
                      <RainbowKitProvider
                        chains={chains}
                        initialChain={mainnet}
                        theme={darkTheme({
                          accentColor: '#2e2e2e',
                          accentColorForeground: 'white',
                          fontStack: 'system',
                          borderRadius: 'medium',
                        })}
                      >
                        <ConnectButtonComponent props={selected.value} />
                      </RainbowKitProvider>
                    </WagmiConfig>
                  </div>
                </s.Container>
              </>
            )}
          </s.Container>
        </ResponsiveWrapper>
        <s.SpacerSmall />
        <s.Container jc={'center'} ai={'center'} style={{ width: '100%' }}>
          <div>
            <StyledIcon>
              <IconItem>
                <a href='https://twitter.com/YureiSpirit'>
                  <Styledsocial
                    className='spin1'
                    alt={'social-icon'}
                    src={'/config/images/icon-1.svg'}
                    height='60px'
                    width='116px'
                  />
                </a>
              </IconItem>
              <IconItem>
                <a href='https://discord.gg/nFh3JAVvnh'>
                  <Styledsocial
                    className='spin2'
                    alt={'social-icon'}
                    src={'/config/images/icon-2.svg'}
                    height='60px'
                    width='116px'
                  />
                </a>
              </IconItem>
            </StyledIcon>
          </div>
          <s.SpacerMedium />
        </s.Container>
      </s.Container>
    </s.Screen>
  );
}

export default App;
