import React, { useState } from 'react';
import styled from 'styled-components';
import phurbaRed from '../assets/images/phurba-full-flame-red-eyes.gif';
import phurbaBeige from '../assets/images/phurba-full-flame-beige-eyes.gif';
import { useUserProvider } from '../context/UserContext';

export const StyledNftImg = styled.img`
  width: 85px;
  border-radius: 10%;
  cursor: pointer;
  height: auto;
`;

const Nfts = ({ props: network }) => {
  const [currentValue, setCurrentValue] = useState();

  // const [one, setOne] = useState(false);
  // const [two, setTwo] = useState(false);
  // const [numIsEven, setNumIsEven] = useState(false);

  const { fn, setFn } = useUserProvider();

  return (
    <div>
      <div className='imgContainer'>
        {fn.tokenIds.map((id) => {
          return (
            <div key={id} className='btnContainer'>
              <button
                className='nft-button'
                onClick={(e) => {
                  e.preventDefault();
                  setCurrentValue(id);
                  setFn((prev) => {
                    return { ...prev, nftTokenSelected: id };
                  });
                }}
                data-button-clicked={currentValue === id ? true : false}
              >
                <StyledNftImg
                  src={id % 2 === 0 ? phurbaRed : phurbaBeige}
                  alt=''
                />
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Nfts;
