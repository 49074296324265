import React, { useEffect, useState } from 'react';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import {
  useAccount,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
  useContractRead,
} from 'wagmi';
import abi from '../contract-abi.json';
import P3Abi from './P3_ABI.json';
import { StyledSelectButton } from '../App';
import { useUserProvider } from '../context/UserContext';
import axios from 'axios';
import { ethers } from 'ethers/lib';
//import assumeRoleAndModifyObjectAcl from './Metadata';

const ConnectButtonComponent = ({ props: network }) => {
  const [selected, setSelected] = useState({
    value: false,
  });
  const [walletBalance, setWalletBalance] = useState(0);
  const [walletBalancePhurba, settBalance] = useState(0);
  const [minted, setMinted] = useState(false);
  //const [updateTokenId, setUpdateTokenId] = useState(0);
  //const [tokenOwnerAddress, setTokenOwnerAddress] = useState('');
  //const [isTokenRight, setIsTokenRight] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [popUp, setPopUp] = useState(false);

  //userContext
  const { fn, setFn } = useUserProvider();

  //account connection
  const { isConnected, address: userWalletAddress } = useAccount();

  //all contract addreses
  let contractAddressOne = '0x54251bc32A9f389DF7c764AB50BB829ccDcB41bc';
  let contractAddressTwo = '0xaC07B10340318A47E6396Bbec0B92611c865D8Cd';
  let contractAddressThree = '0x3591af9168c0049ed9ee63fc0ee5713657a953c9';
  //need to featch this
  let walletAddress = String(userWalletAddress);

  //API call
  useEffect(() => {
    async function getData() {
      try {
        const options = {
          method: 'GET',
          url: `https://api.nftport.xyz/v0/accounts/${walletAddress}`,
          params: {
            chain: 'ethereum',
            page_size: '50',
            include: 'default',
            contract_address: contractAddressTwo,
          },
          headers: {
            accept: 'application/json',
            Authorization: process.env.REACT_APP_APIKEY,
          },
        };

        const response = await axios.request(options);
        setApiData(response.data.nfts);
        
      } catch (error) {}
    }
    getData();
    //recall get data on txSuccess popUp
    if (popUp) {
      setTimeout(() => {
        getData();
      }, 500);
    }
  }, [walletAddress, popUp]);
  
  const { data: TheSupply } = useContractRead({
    address: contractAddressThree,
    abi: P3Abi,
    functionName: 'totalSupply',
    watch: true,
    onSuccess(data) {
      setFn((prevFn) => {
        return {
          ...prevFn,
          supply: data.toNumber()
        };
      });
    },

  });
  //console.log(TheSupply.toNumber());
  // for loop that loads tokenID to an array
  let token_ids = [];
  for (let i = 0; i < apiData.length; i++) {
    token_ids.push(apiData[i].token_id);
  }

  //get wallet balance Yurei
  const { } = useContractRead({
    address: contractAddressOne,
    abi: abi,
    functionName: 'balanceOf',
    args: [walletAddress],
    onSuccess(data) {
      setWalletBalance(data.toNumber());
    },
  });

  const { } = useContractRead({
    address: contractAddressThree,
    abi: P3Abi,
    functionName: 'FriendListCount',
    args: [walletAddress],
    onSuccess(data) {
      setMinted(data);
    },
  });

  //get wallet balance Phurba
  const { } = useContractRead({
    address: contractAddressTwo,
    abi: abi,
    functionName: 'balanceOf',
    args: [walletAddress],
    onSuccess(data) {
      settBalance(data.toNumber());
    },
  });

  //confirm if token is right for user
  /*const { data } = useContractRead({
    address: contractAddressTwo,
    abi: abi,
    functionName: 'ownerOf',
    args: [updateTokenId],
    onSettled(data, error) {
      if (data === walletAddress) {
        setIsTokenRight(true);
      } else {
        setIsTokenRight(false);
      }
    },
  });*/

  //check if approved/setApproval
  const {} = useContractRead({
    address: contractAddressTwo,
    abi: P3Abi,
    functionName: 'isApprovedForAll',
    args: [walletAddress, contractAddressThree],
    watch: true,
    onSettled(data) {
      setIsApproved(data);
    },
  });
  // console.log('canBurnAndMint:', isApproved);

  // setIsApprovedForall work on this next
  const { config: setApprovalForAll } = usePrepareContractWrite({
    address: contractAddressTwo,
    abi: P3Abi,
    functionName: 'setApprovalForAll',
    args: [contractAddressThree, true],
  });
  const { data: setApprovalForAllData, write: setApproval } =
    useContractWrite(setApprovalForAll);

  // public mint
  const { config: publicContactThreeMint } = usePrepareContractWrite({
    address: contractAddressThree,
    abi: P3Abi,
    functionName: 'mintPublic',
    overrides: {
      //value of eth in wei
      value: ethers.utils.parseEther('0.05'),
      gasLimit: 150000,
    },
    onSuccess(data,error){
    }
    
  });

  const { 
    data: publicData,
    write: publicMint,
    isError: MintErr,
    isLoading: MintLoading,
    isSuccess: MintStarted, } = useContractWrite(publicContactThreeMint);
  
  //burn and mint
  const { config } = usePrepareContractWrite({
    // ...contractConfig,
    address: contractAddressThree,
    abi: P3Abi,
    functionName: 'BurnAndMint',
    // what do i add in here, tokenId?
    args: [fn.nftTokenSelected],
    overrides: {
      //value of eth in wei
      value: ethers.utils.parseEther('0.005'),
      gasLimit: 185000,
    },
  });

  const {
    data: burnData,
    write: burnMint,
    isError: burnMintErr,
    isLoading: burnNMintLoading,
    isSuccess: burnMintStarted,
  } = useContractWrite(config);

  //wait for transaction

  //burn and mint
  const { config: PhurbaMint } = usePrepareContractWrite({
    // ...contractConfig,
    address: contractAddressThree,
    abi: P3Abi,
    functionName: 'BurnPhurbaMint',
    // what do i add in here, tokenId?
    args: [fn.nftTokenSelected],
    overrides: {
      //value of eth in wei
      value: ethers.utils.parseEther('0.01'),
      gasLimit: 170000,
    },
  });

  const {
    data: PhurbaburnData,
    write: PhurbaburnMint,
    isError: PhurbaburnMintErr,
    isLoading: PhurbaburnNMintLoading,
    isSuccess: PhurbaburnMintStarted,
  } = useContractWrite(PhurbaMint);


  //select private/public
  const togglePrivate = () => {
    setSelected((prevSelect) => {
      return {
        value: 'PRIVATE',
      };
    });
    //useUSerContext to set network and tokenIds
    setFn((prevFn) => {
      return {
        ...prevFn,
        selectedNetwork: 'PRIVATE',
        tokenIds: token_ids,
      };
    });
  };
  //reload UseUserProvider on TxSuccess popUp
  const reload = () => {
    setFn((prevFn) => {
      return {
        ...prevFn,
        tokenIds: token_ids,
      };
    });
  };

  //toggle public network
  const togglePublic = () => {
    setSelected((prevSelect) => {
      return {
        value: 'PUBLIC',
      };
    });
  };
  const {
    data: txData,
    isSuccess: txSuccess,
    isLoading: txLoading,
    isError: txFailed,
  } = useWaitForTransaction({
    hash: burnData?.hash,
    watch: true,
    onSettled(data, error) {},
    onSuccess(data, error) {
      //setMinted(minted++)
      //console.log(data, error);
      //setPopUp(true);
      // setReloadState(true)
      // setTimeout(() => {
      //   setReloadState(false)
      // }, 1000);
    },
  });
  const {
    data: txDataP,
    isSuccess: txSuccessP,
    isLoading: txLoadingP,
    isError: txFailedP,
  } = useWaitForTransaction({
    hash: publicData?.hash,
    watch: true,
    //onSettled(data, error) {},
    onSuccess(data) {
      //console.log('success',data);
      //setPopUp(true);
      // setReloadState(true)
      // setTimeout(() => {
      //   setReloadState(false)
      // }, 1000);
    },
    onSettled(data, error) {
      //console.log('Settled', { data, error })
      //let {logs:[{topics}]} = data;
      //let thirdTopic = topics[3];
      //let decimalValue = parseInt(thirdTopic, 16);
      //assumeRoleAndModifyObjectAcl("yurei",decimalValue)
      //console.log(thirdTopic);
      //console.log(decimalValue);
    },

    //let MintedToken.json() = JSON.stringify(data);
  });
//console.log(JSON.stringify(txDataP.logs[0].topics[3]))
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <div className='connectBtn'>
        <ConnectButton
          label='Connect'
          accountStatus={{
            smallScreen: 'avatar',
            largeScreen: 'full',
          }}
          chainStatus='none'
        />
      </div>

      {isConnected && selected.value === false && (
        <div>
          <StyledSelectButton
            disabled={walletBalancePhurba === 0 ? 1 : 0}
            onClick={(e) => {
              e.preventDefault();
              togglePrivate();
              // togglePopUp();
            }}
          >
            private
          </StyledSelectButton>
          <StyledSelectButton
            disabled={minted}
            onClick={(e) => {
              e.preventDefault();
              togglePublic();
            }}
          >
            public
          </StyledSelectButton>
        </div>
      )}
      {isConnected && walletBalance === 0 && walletBalancePhurba === 0 && selected.value === false && (
        <div
          className=''
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            color: 'var(--accent-text)',
            zIndex: '1',
          }}
        >
          <br />
          <p>You do not own a Yurei NFT</p>
          
        </div>
      )}

      {/* mint component */}
      {/* PRIVATE MINT */}
      {isConnected && selected.value === 'PRIVATE' && walletBalancePhurba > 0  && (
        <div>
          {isApproved && (
            <StyledSelectButton
              style={{
                marginTop: '10px',
              }}
              disabled={burnNMintLoading || txLoading || PhurbaburnNMintLoading /*|| (minted === walletBalance)*/}
              onClick={() => {
                // mint?.();
                // setApproval?.();
                // sendTx?.();
                walletBalance === 0 ? PhurbaburnMint?.() : burnMint?.();
              }}
            >
              {burnNMintLoading && 'Busy...'}
              {PhurbaburnNMintLoading && 'Busy...'}
              {txLoading && 'Minting...'}
              {!burnNMintLoading && !txLoading && !PhurbaburnNMintLoading && 'Mint'}
              {/* {txSuccess && <p>Transaction Success</p>} */}
            </StyledSelectButton>
          )}

          {!isApproved && (
            <StyledSelectButton
              style={{
                marginTop: '10px',
              }}
              
              onClick={() => {
                setApproval?.();
              }}
            >
             {txLoading && 'Minting...'}
             {!MintLoading && !txLoading && 'Approve'}
            </StyledSelectButton>
          )}
        </div>
      )}
      {/* //publicmint */}
      {isConnected && selected.value === 'PUBLIC' && (
        <StyledSelectButton
        disabled={MintLoading || txLoadingP || minted}
        onClick={() => {
          publicMint?.();
        }}
        >
              {MintLoading && 'Busy...'}
              {txLoadingP && 'Minting...'}
              {!MintLoading && !txLoadingP && 'Mint'}
            </StyledSelectButton>

      )}
      <br />
       {(txSuccess || txSuccessP) && (
        <p
        style={{ textAlign: "center", color: "var(--accent-text)" }}
        >
          Mint Successful!!
        </p>
      )} 

        {(walletBalancePhurba === 0) && isConnected && (
        <p
        style={{ textAlign: "center", color: "var(--accent-text)" }}
        >
          You dont own any Phurba
        </p>
      )}

      {isConnected && selected.value === 'PUBLIC' && minted && (
        <p
        style={{ textAlign: "center", color: "var(--accent-text)" }}
        >
          You used all your mints
        </p>
      )} 

      {(txFailed || txFailedP || burnMintErr || PhurbaburnMintErr) && (
        <p
          style={{
            fontSize: '1rem',
            color: 'white'
          }}
        >
          Transaction failed, Please try Again
        </p>
      )}
      <br />
      {/* txSuccess Overlay */}
      {/*popUp && (
        <div className='txSuccessPopUp'>
          <div className='overlay'></div>
          <div className='sucesss popUp'>
            <p>Transaction Successful</p>
            <button
              onClick={(e) => {
                // e.preventDefault();
                //togglePrivate();
                setPopUp(false);
                reload();
              }}
            >
              Mint Again
            </button>
          </div>
        </div>
      )*/}
    </div>
  );
  exports.Sup = TheSupply;
};


export default ConnectButtonComponent;
